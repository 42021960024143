.account {
    display: flex;
    column-gap: 20px;
}

@media screen and (max-width: 1000px){
    .account {
        flex-direction: column;
        row-gap: 10px;
    }
}

.account-box {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 50%;
    background: rgba(251, 251, 251, 0.3);
    padding: 10px;
}

@media screen and (max-width: 1000px){
    .account-box {
        width: 100%;
    }
}

.account-box-label {
    font-size: 14px;
    margin-bottom: 3px;
}

.account-form-label {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

.account-button-container {
    margin-top: 10px;
    text-align: center;
}