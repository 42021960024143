* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Lato", "sans-serif";
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #141414;
    color: #FFFFFF;
    height: 100%;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url(./fonts/Lato/Lato-Regular.ttf) format('opentype');
}

:root {
    --content-width: 1100px;
}

a {
    color: white;
    text-decoration: none;
}


div {
    box-sizing: border-box;
}

.container {
    position: relative;
}

@media screen and (max-width: 1000px) {
    .container {
        box-sizing: border-box;
    }
}


.content {
    width: var(--content-width);
    margin: 0 auto;
    height: 100%;
}

@media screen and (max-width: 1000px) {
    .content {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
    }
}


h1 {
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
}

h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
}

h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
}

select {
    width: 157px;
    height: 48px;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
}

input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"], select {
    width: 100%;
    font-size: 16px;
    padding: 12px;
    height: 35px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: none;
    border-radius: 4px;
    transition: all .2s;
    color: #22292E;
}

input[type="text"]:disabled, input[type="email"]:disabled, input[type="tel"]:disabled, input[type="number"]:disabled {
    color: #989898;
}

select {
    color: #989898;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="tel"]:focus, input[type="number"]:focus {
    outline: none 0 !important;
    border-color: #2F80ED !important;
}

/*input:focus + label,*/
/*input:not(:placeholder-shown) + label {*/
/*    transform: translateY(4px) scale(1);*/
/*}*/

/*input[type="text"]::placeholder, input[type="email"]::placeholder, input[type="password"]::placeholder, input[type="tel"]::placeholder, input[type="number"]::placeholder {*/
/*    color: transparent;*/
/*}*/

input[type="text"]:focus:invalid, input[type="email"]:focus:invalid, input[type="password"]:focus:invalid, input[type="tel"]:focus:invalid, input[type="number"]:focus:invalid {
    border-color: #D8000C !important;
}

input[type="checkbox"] {
    margin-right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/*label {*/
/*    cursor: auto;*/
/*    position: absolute;*/
/*    top: -25px;*/
/*    left: 12px;*/
/*    color: #414141;*/
/*    display: block;*/
/*    opacity: 1;*/
/*    transform: translateY(2.4em);*/
/*    transform-origin: 0 0;*/
/*    transition: all .2s;*/
/*    z-index: 0;*/
/*}*/

button {
    font-style: normal;
    width: 180px;
    height: 48px;
    border-radius: 25px;
    color: #FFFFFF;
    background-color: #1DAB5F;
    font-weight: 500;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
}


@media screen and (max-width: 1000px) {
    h1 {
        font-size: 32px;
        line-height: 56px;
        margin-bottom: 25px;
        text-align: center;
    }

    h2 {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 25px;
        text-align: center;
    }

    h3 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 25px;
        text-align: center;
    }

    p, ul > li {
        font-size: 18px;
        line-height: 30px;
    }

    input {
        width: 160px;
        height: 48px;
    }
}

.form-item {
    position: relative;
}

.form-button-container {
    margin: 30px auto 0;
    width: 100%;
    min-height: 35px;
    display: flex;
    justify-content: center;
}

.logo {
    background: url("./images/logo.png") no-repeat;
    background-size: contain;
    width: 520px;
    height: 79px;
    margin: 0 auto;
}

@media screen and (max-width: 1024px){
    .logo {
        width: 100%;
    }
}

.logo-small {
    background: url("./images/logo-small.png") no-repeat;
    background-size: contain;
    width: 40px;
    height: 30px;
    cursor: pointer;
}

.logout {
    background: url("./images/logout.png") no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.upload-icon {
    background: url("./images/upload.png") no-repeat;
    background-size: contain;
    width: 14px;
    height: 14px;
}
