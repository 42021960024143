.modal-bg {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.modal {
    background: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    color: #000000;
    font-size: 22px;
    line-height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    outline: none;
    width: auto;
    min-width: 500px;
    height: auto;
    padding: 30px;
    box-sizing: border-box;
}

@media screen and (max-width: 1024px){
    .modal {
        min-width: 90%;
    }
}

.modal-close-button {
    position: absolute;
    right: 15px;
    top: 5px;
    cursor: pointer;
    font-size: 30px;
    font-weight: 300;
    transform: rotate(45deg);
}

.modal-input {
    border: 1px solid gray !important;
}

.modal-button-container {
    margin-top: 20px;
}
