.header {
    height: 60px;
    background: rgba(217, 217, 217, 0.3);
}

.header-container {
    width: var(--content-width);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

@media screen and (max-width: 1024px){
    .header-container {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.main-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
}

@media screen and (max-width: 1024px){
    .main-menu {
        width: 80%;
        font-size: 14px;
    }
}

.main-menu > div {
    cursor: pointer;
    font-weight: 300;
}