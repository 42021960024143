.dashboard-container {
    background: url("../../../images/bg.jpeg") no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 50px;
}

.dashboard-wrapper {
    width: var(--content-width);
    margin: 50px auto 0;
}

@media screen and (max-width: 1024px){
    .dashboard-wrapper {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}