.landing-content {
    text-align: center;
    width: var(--content-width);
    padding-top: 50px;
}

@media screen and (max-width: 1024px){
    .landing-content {
        width: 100%;
    }
}

.landing-description {
    margin-top: 30px;
    margin-bottom: 80px;
}

.invitation-link {
    cursor: pointer;
    margin-top: 20px;
}

.reset-password {
    position: absolute;
    background: rgba(251, 251, 251, 0.3);
    padding: 30px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 400px;
    height: 180px;
}

@media screen and (max-width: 1024px){
    .reset-password {
        width: 90%;
    }
}
