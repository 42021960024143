.kyc {
    width: 800px;
    text-align: center;
    margin: 0 auto;
}

@media screen and (max-width: 1024px){
    .kyc {
        width: 100%;
    }
}

.kyc-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
}

.kyc-table {
    margin: 50px auto 0;
}

.kyc-upload-button {
    width: 120px;
    padding: 7px 20px;
    background: #4592FF;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.kyc-button-container {
    margin-top: 10px;
    text-align: center;
}

.kyc-table > tbody > tr > td:first-child {
    text-align: left;
    padding-right: 50px;
}

.kyc-table > tbody > tr > td:last-child {
    text-align: right;
}

.kyc-table > tbody > tr > td {
    padding-bottom: 20px;
}

.kyc-hint {
    font-size: 12px;
}

.kyc-filename {
    font-size: 10px;
}

