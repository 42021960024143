.login-form {
    width: 400px;
    margin: 0 auto;
}

@media screen and (max-width: 1024px){
    .login-form {
        width: 100%;
    }
}

.login-title {
    margin-bottom: 20px;
}

.forgot-password-link {
    margin-top: -5px;
    cursor: pointer;
    text-align: right;
}

.login-button-container {
    text-align: center;
    margin-top: 10px;
}