.project {
    width: 800px;
    text-align: center;
    margin: 0 auto;
}

@media screen and (max-width: 1024px){
    .project {
        width: 100%;
    }
}

.project-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
}

@media screen and (max-width: 1024px){
    .project-title {
        font-size: 22px;
        line-height: 28px;
    }
}


.project-video {
    margin-top: 80px;
}