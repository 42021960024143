.holdings {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

@media screen and (max-width: 1024px){
    .holdings {

    }
}

.holdings-box-container {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}

@media screen and (max-width: 1024px){
    .holdings-box-container {
        flex-direction: column;
        row-gap: 20px;
    }
}


.holdings-box {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 50%;
    background: rgba(68, 68, 68, 0.3);
    padding: 10px;
}

@media screen and (max-width: 1000px){
    .holdings-box {
        width: 100%;
    }
}

.holdings-box > table > tbody > tr > td:first-child {
    text-align: left;
}

.holdings-box > table > tbody > tr > td:last-child {
    text-align: right;
}

.holdings-box > table > tbody > tr > td {
    padding-bottom: 8px;
}

.holdings-chart {
    margin-top: 20px;
    background: rgba(68, 68, 68, 0.3);
    padding: 10px;
}

.holdings-chart-title {
    font-size: 18px;
    margin-bottom: 10px;
}